<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:#fff">Token Buy Details
            </h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class=" input-style">
                        <form [formGroup]="addFaqForm">

                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="user list">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">Rules
                                            </th>
                                            <th scope="col">Date of Release
                                            </th>
                                            <th scope="col">Token Amount



                                            </th>
                                            <!--         <th scope="col">User ID</th> -->

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr *ngFor="let item of listing | paginate: { 
                                    itemsPerPage: itemsPerPage,
                                    currentPage: pageNumber,
                                    totalItems: totalRecords };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td class="name">{{item?.firstName}}  {{item?.lastName}}</td>
                                        <td>{{item?.email || 'N/A'}}</td>
                                        
                                        <td>{{item?.phoneNo || 'N/A'}}</td>
                                        <td>{{item?.country || 'N/A'}}</td>

                                        <td>{{item?.userStatus || 'N/A'}}</td>

                                        <td>{{item?.createTime | date: 'medium'}}</td>
                                        <td>{{item?.createTime | date: 'medium'}}</td>
                                        <td>{{item?.createTime | date: 'medium'}}</td>
                                        <td>{{item?.createTime | date: 'medium'}}</td>

                                        <td class="action_td_btn3">
                                            <a routerLink="/user-details/{{item.userId}}"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="delete(item.userId)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('ACTIVE',item?.userId)" *ngIf="item?.userStatus=='BLOCK'"
                                                data-toggle="modal"><i class="fa fa-ban" style="color: green;"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('BLOCK',item?.userId)" *ngIf="item?.userStatus=='ACTIVE'"
                                                data-toggle="modal"><i class="fa fa-ban" aria-hidden="true"></i></a>

                                        </td>
                                    </tr> -->



                                        <tr>
                                            <td>First Installment Of token

                                            </td>
                                            <td>20 March-2023



                                            </td>
                                            <td>100000


                                            </td>

                                        </tr>

                                    </tbody>

                                </table>
                            </div>

                            <div class="text-center mt40">
                                <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                    routerLink="/user-details/{{userId}}">Back</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>