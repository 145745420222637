<main class="middle-content">
    <!-----page title start--->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">News & Letter Management</h1>
    </div>
    <!-----page title end  ---->
    <!-----table responsive start---->
    <div class="content-section">
        <div class="add-new-header-btn-align text-right">
            <button class="btn btn-success btn-theme" routerLink="/news-letter-add">Add News</button>
        </div>
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="table-responsive">
                        <table class="table table-bordered" aria-describedby="Advertisement management list">
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th scope="col">S.No.</th>
                                    <th scope="col" class="Sorting-img">Image</th>
                                    <th scope="col" class="Sorting-img">Title</th>
                                    <!-- <th scope="col" class="Sorting-img">Status</th> -->
                                    <th scope="col" class="Sorting-img">Posted Date & Time</th>

                                    <th scope="col" class="action_td_btn3 Sorting-img">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of  advertisementList | paginate: {itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems: total}; let i = index"
                                    class="text-center">
                                    <td>{{itemsPerPage * (currentPage -1)+i+1}}</td>
                                    <td><img [src]="item.imageUrl" alt="img" width="50px"></td>
                                    <td>{{item?.title||"--"}}</td>
                                    <!-- <td>{{item?.isDeleted ? 'Active' : 'Deactive'||"--"}}</td> -->
                                    <td>{{(item?.publishedTime| date : 'MM/dd/yyyy, h:mm a')||"--"}}</td>

                                    <td class="action_td_btn3" style="cursor: pointer">
                                        <a><em class="fa fa-eye" (click)="viewAdvertisement(item.newsLetterId)"
                                                title="View"></em></a>
                                        <a><em class="fa" (click)="editAdvertisement(item.newsLetterId)"
                                                title="Edit">&#xf044;</em></a>
                                        <a data-target="#deleteModal" data-toggle="modal"><em class="fa fa-trash"
                                                (click)="openDeleteBlogModal(item.newsLetterId)"
                                                title="Delete"></em></a>


                                        <!-- <label class="switch" style="top: 8px;">
                                                      <input type="checkbox" [value] = "isActive[i]" [(ngModel)]="isActive[i]">
                                                      <span class="slider round"
                                                          (click)="changeBannerStatus(!isActive[i],item?.id)"
                                                          [title]="(isActive[i]==true)?'Enabled':'Disabled'"></span>
                                                  </label> -->
                                    </td>

                                </tr>
                                <!-- <tr *ngIf="total ==0">
                                    <td colspan="11" vertical-align="middle">
                                        <div class="no-record">
                                            <div class="no-recordin">
                                                <H5>No record found</H5>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->
                                <tr *ngIf="advertisementList?.length==0">
                                    <td colspan="10" vertical-align="middle">
                                        <div class="no-record">
                                            <div class="no-recordin">
                                                <H5>No record found</H5>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total > itemsPerPage">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-----table responsive end---->
</main>


<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteBlog">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete News </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this News?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="deleteAdvertisement()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->