<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:#fff">Create New Plan

            </h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class=" input-style">
                        <form [formGroup]="addFaqForm">
                            <div class="form-group row ">

                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox">Plan Name:

                                    </label>
                                    <input type="text" class="form-control " maxlength="56" placeholder="Plan Name"
                                        formControlName="planName" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('planName').hasError('required') && (addFaqForm.get('planName').dirty || addFaqForm.get('planName').touched )"
                                            class="error" padding>Plan name is required</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox mb-0"> Token Price:
                                        <span>
                                            <label class="col-md-4" class="labelBox ml-3 mb-0">Mark up/down Price:
                                                <input type="radio" value="TRANC" (change)="onOptionChange()"
                                                    (click)="openModalFun()" formControlName="salePrice" id="option1"
                                                    class="radionType" aria-selected="true">
                                            </label>
                                            <label class="col-md-4" class="labelBox ml-2 mb-0">Price:
                                                <input type="radio" value="POOL" formControlName="salePrice"
                                                    id="option2" class="radionType">
                                            </label>
                                        </span>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Token Price"
                                        formControlName="tokenPrice" maxlength="16" (keypress)="service.preventSpace($event)"
                                        [readonly]="addFaqForm.value.salePrice === 'TRANC'" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('tokenPrice').hasError('required') && (addFaqForm.get('tokenPrice').dirty || addFaqForm.get('tokenPrice').touched )"
                                            class="error" padding>Token price is required</p>
                                        <p *ngIf="addFaqForm.get('tokenPrice').hasError('pattern') && (addFaqForm.get('tokenPrice').dirty || addFaqForm.get('tokenPrice').touched )"
                                            class="error" padding>Invalid token price</p>
                                    </span>

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Token Sell Limit
                                    </label>
                                    <input type="text" class="form-control " maxlength="16" placeholder="Token Sell Limit"
                                        formControlName="tokenSellLimit" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('tokenSellLimit').hasError('required') && (addFaqForm.get('tokenSellLimit').dirty || addFaqForm.get('tokenSellLimit').touched )"
                                            class="error" padding>Token sell limit is required</p>
                                        <p *ngIf="addFaqForm.get('tokenSellLimit').hasError('pattern') && (addFaqForm.get('tokenSellLimit').dirty || addFaqForm.get('tokenSellLimit').touched )"
                                            class="error" padding>Invalid token sell limit</p>
                                    </span>
                                    <!-- 
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('phoneNumber').hasError('pattern') && (addFaqForm.get('phoneNumber').dirty || addFaqForm.get('phoneNumber').touched )"
                                            class="error" padding>*Please enter valid phone number</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Terms (Days)



                                    </label>
                                    <select class="form-control " formControlName="terms">
                                        <option value="">Select Days</option>
                                        <option *ngFor="let item of daysList" [value]="item?.name">{{item.name}}
                                        </option>

                                    </select>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('terms').hasError('required') && (addFaqForm.get('terms').dirty || addFaqForm.get('terms').touched )"
                                            class="error" padding>Terms is required.</p>
                                    </span>
                                    <!-- <input type="text" class="form-control " formControlName="terms"
                                        (keypress)="service.preventSpace($event)" [readonly]="userId"
                                        style="text-transform:lowercase ;" /> -->

                                    <!-- <
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('emailId').hasError('pattern') && (addFaqForm.get('emailId').dirty || addFaqForm.get('emailId').touched )"
                                            class="error" padding>*Please enter valid email id</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Withdrawal Commission:

                                    </label>
                                    <input type="text" class="form-control " maxlength="3" placeholder="Withdrawal Commission"
                                        formControlName="withdrawaCommission"
                                        (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('withdrawaCommission').hasError('required') && (addFaqForm.get('withdrawaCommission').dirty || addFaqForm.get('withdrawaCommission').touched )"
                                            class="error" padding>Withdrawal commission is required.</p>
                                        <p *ngIf="addFaqForm.get('withdrawaCommission').hasError('pattern') && (addFaqForm.get('withdrawaCommission').dirty || addFaqForm.get('withdrawaCommission').touched )"
                                            class="error" padding>Invalid withdrawal commissiont</p>
                                    </span>
                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('lastName').hasError('required') && (addFaqForm.get('lastName').dirty || addFaqForm.get('lastName').touched )"
                                            class="error" padding>*Last Name is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('lastName').hasError('pattern') && (addFaqForm.get('lastName').dirty || addFaqForm.get('lastName').touched )"
                                            class="error" padding>*Please enter valid last name</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox">Release Time Period (Days)



                                    </label>
                                    <select class="form-control " formControlName="releaseTimePeriod">
                                        <option value="">Select Days</option>
                                        <option *ngFor="let item of dataListRelease" [value]="item?.name">{{item.name}}
                                        </option>

                                    </select>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('releaseTimePeriod').hasError('required') && (addFaqForm.get('releaseTimePeriod').dirty || addFaqForm.get('releaseTimePeriod').touched )"
                                            class="error" padding>Release time period is required.</p>
                                    </span>
                                    <!-- <input type="text" class="form-control " formControlName="terms"
                                        (keypress)="service.preventSpace($event)" [readonly]="userId"
                                        style="text-transform:lowercase ;" /> -->

                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('emailId').hasError('required') && (addFaqForm.get('emailId').dirty || addFaqForm.get('emailId').touched )"
                                            class="error" padding>*Email ID is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('emailId').hasError('pattern') && (addFaqForm.get('emailId').dirty || addFaqForm.get('emailId').touched )"
                                            class="error" padding>*Please enter valid email id</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Plan Start Date:


                                    </label>
                                    <input type="date" class="form-control" formControlName="planStartdate"
                                        [max]="today" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('planStartdate').hasError('required') && (addFaqForm.get('planStartdate').dirty || addFaqForm.get('planStartdate').touched )"
                                            class="error" padding>Plan start date is required.</p>
                                    </span>
                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('dob').hasError('required') && (addFaqForm.get('dob').dirty || addFaqForm.get('dob').touched )"
                                            class="error" padding>*Date of Birth is required</p>
                                    </span> -->
                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('dob').hasError('pattern') && (addFaqForm.get('dob').dirty || addFaqForm.get('dob').touched )"
                                            class="error" padding>*Please enter valid date of birth</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Plan End Date:


                                    </label>
                                    <input type="date" class="form-control" formControlName="planEndDate"
                                        onkeydown="return false"
                                        [readonly]="!this.addFaqForm.controls['planStartdate'].value"
                                        [min]="addFaqForm.controls['planStartdate'].value"
                                        (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('planEndDate').hasError('required') && (addFaqForm.get('planEndDate').dirty || addFaqForm.get('planEndDate').touched )"
                                            class="error" padding>Plan end date is required.</p>
                                    </span>
                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('dob').hasError('required') && (addFaqForm.get('dob').dirty || addFaqForm.get('dob').touched )"
                                            class="error" padding>*Date of Birth is required</p>
                                    </span> -->
                                    <!-- <span class="error">
                                        <p *ngIf="addFaqForm.get('dob').hasError('pattern') && (addFaqForm.get('dob').dirty || addFaqForm.get('dob').touched )"
                                            class="error" padding>*Please enter valid date of birth</p>
                                    </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Image:

                                    </label>
                                    <input type="file" accept=".jpg, .jpeg, .png" (change)="onFileSelected($event)"
                                        class="form-control " formControlName="image" />

                                    <img [src]="myImage" alt="" class="chat-attachment-img" />

                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Min GBP Amount
                                    </label>
                                    <input type="text" class="form-control " maxlength="16" placeholder="Min GBP Amount"
                                        formControlName="minGbpAmount" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('minGbpAmount').hasError('required') && (addFaqForm.get('minGbpAmount').dirty || addFaqForm.get('minGbpAmount').touched )"
                                            class="error" padding>Minimum GBP amount is required</p>
                                        <p *ngIf="addFaqForm.get('minGbpAmount').hasError('pattern') && (addFaqForm.get('minGbpAmount').dirty || addFaqForm.get('minGbpAmount').touched )"
                                            class="error" padding>Invalid GBP amount</p>
                                    </span>
                                    <!-- 
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('phoneNumber').hasError('pattern') && (addFaqForm.get('phoneNumber').dirty || addFaqForm.get('phoneNumber').touched )"
                                            class="error" padding>*Please enter valid phone number</p>
                                    </span> -->
                                </div>

                            </div>
                            <div class="text-center mt40">
                                <button class="btn btn-large  max-WT-200 font-100 btn-grey mr-5"
                                    routerLink="/vesting-plan-list">Cancel</button>
                                <button *ngIf="!isLoading" class="btn btn-large  max-WT-200 font-100 btn-green "
                                    [disabled]="addFaqForm.invalid" (click)="contractDeploy()">Add</button>
                                <button *ngIf="isLoading" class="btn btn-large  max-WT-200 font-100 btn-green "
                                    disabled>Loading...</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<!-- HTML code for the modal -->
<div class="modal fade" id="imgCheck" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <img [src]="myImageNew" alt="Image" class="img-fluid">
            </div>
            <div class="modal-footer btn-modal">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="button" class="btn btn-large  max-WT-200 font-100 btn-green"
                    (click)="imageUplaod()">Submit</button>
            </div>
        </div>
    </div>
</div>
<!-- HTML code for the modal -->
<div class="modal fade" id="markUpModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">
                    Mark up/down</h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div *ngIf="addFaqForm.value.salePrice === 'TRANC'" class="markup">
                    <div class="d-flex justify-content-center mb-30">
                        <div class="mark-selector">
                            <input type="radio" [(ngModel)]="markTab" (change)="setMarkTab($event.target.value)"
                                id="markup" name="markType" value="UP">
                            <label for="markup">Markup</label>
                        </div>
                        <div class="mark-selector">
                            <input type="radio" [(ngModel)]="markTab" (change)="setMarkTab($event.target.value)"
                                id="makdown" name="markType" value="DOWN">
                            <label for="makdown">Markdown</label>
                        </div>
                        <div class="mark-selector">
                            <input type="radio" [(ngModel)]="markTab" (change)="setMarkTab($event.target.value)"
                                id="nomarkup" name="markType" value="NOMARK">
                            <label for="nomarkup">No Mark up/down</label>
                        </div>
                    </div>

                    <ng-container *ngIf="markTab == 'UP'">
                        <div class="row justify-content-center mt-5 m-auto">
                            <div class="col-3">
                                <p class="label">Mark up %</p>
                            </div>
                            <div class="col-1">
                                <p class="label">:</p>
                            </div>
                            <div class="col-4">
                                <input type="number" (input)="setFinalPrice($event.target.value)"
                                    [(ngModel)]="markUpPercentage"
                                    onkeyup="if(this.value >100) this.value = 100; if(this.value < 0) this.value = 0"
                                    class="form-control percent" placeholder="Markup percentage">
                            </div>
                        </div>
                        <div class="row justify-content-center  m-auto">
                            <div class="col-3">
                                <p class="label">Final Price</p>
                            </div>
                            <div class="col-1">
                                <p class="label">:</p>
                            </div>
                            <div class="col-4">
                                <p class="label">{{finalPrice}} USD</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button type="submit" [disabled]="finalPrice == '0.00'"
                                    class="btn  btn-theme cus-mr save-btn" (click)="setMarkupPercentage()">Save</button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="markTab == 'DOWN'">
                        <div class="row justify-content-center mt-5  m-auto">
                            <div class="col-3">
                                <p class="label">Mark down %</p>
                            </div>
                            <div class="col-1">
                                <p class="label">:</p>
                            </div>
                            <div class="col-4">
                                <input type="number" (input)="setFinalPrice($event.target.value)"
                                    [(ngModel)]="markDownPercentage"
                                    onkeyup="if(this.value > 100) this.value = 100; if(this.value < 0) this.value = 0"
                                    class="form-control percent" placeholder="Markup percentage">
                            </div>
                        </div>
                        <div class="row justify-content-center  m-auto">
                            <div class="col-3">
                                <p class="label">Final Price</p>
                            </div>
                            <div class="col-1">
                                <p class="label">:</p>
                            </div>
                            <div class="col-4">
                                <p class="label">{{finalPrice}} USD</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button type="submit" [disabled]="finalPrice == '0.00'"
                                    class="btn  btn-theme cus-mr save-btn" (click)="setMarkupPercentage()">Save</button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="markTab == 'NOMARK'">
                        <div class="row justify-content-center mt-5  m-auto">
                            <div class="col-3">
                                <p class="label">No Markup/down %</p>
                            </div>
                            <div class="col-1">
                                <p class="label">:</p>
                            </div>
                            <div class="col-4">
                                <input type="number" class="form-control percent" value="0.00" readonly
                                    placeholder="Markup percentage">
                            </div>
                        </div>
                        <div class="row justify-content-center  m-auto">
                            <div class="col-3">
                                <p class="label">Final Price</p>
                            </div>
                            <div class="col-1">
                                <p class="label">:</p>
                            </div>
                            <div class="col-4">
                                <p class="label">{{toFixed(fieroPrices,2)}} USD</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button type="submit" class="btn  btn-theme cus-mr save-btn"
                                    (click)="setMarkupPercentage()">Save</button>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
            <!-- <div class="modal-footer btn-modal">
          <button type="button" class="btn btn-large  max-WT-200 font-100 btn-green" >Submit</button>
        </div> -->
        </div>
    </div>
</div>