<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">FIERES ACADEMY MANAGEMENT</h1>
    </div>
    <!-- tab end -->
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="row  d-flex justify-content-end w_100">

                            <!-- <button   type="submit" class="btn  btn-theme faq" style="margin-right: 1.3%;"
                                (click)="addFaq()">Add Academy
                            </button> -->
                            <select class="form-control " (change)="pageNumber=1;faqList()" [(ngModel)]="selectedLanguage" style="    width: 250px;
                            margin-right: 30px;">
                                <option  value="">Select Language</option>
                                <option [value]="item.name" *ngFor="let item of languageArrs">{{item.name}} &nbsp;{{item.nativeName}}</option>
                              </select>
                            <button *ngIf="service.isShow(permissionArr,'FIERES_TUTORIAL','CREATE')" type="submit"
                                class="btn  btn-theme faq" style="margin-right: 1.3%;" (click)="addFaq()">Add Academy
                            </button>
                        </div>
                        <!-- tab english faq start -->
                        <div class="tab-pane active show" style="margin-top: 20px;">
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="user list">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">S.No.</th>
                                            <th scope="col">Title <i class="fa fa-sort"
                                                    (click)="sortAgent('title');isAssending=!isAssending"
                                                    aria-hidden="true" style="cursor: pointer;"></i></th>
                                            <th scope="col">Language </th>
                                            <th scope="col">Video Url <i class="fa fa-sort"  aria-hidden="true" (click)="sortAgent('videoUrl')"
                                                    style="cursor: pointer;"></i></th>
                                            <th scope="col">Date & Time <i class="fa fa-sort"
                                                    (click)="sortAgent('createdAt');isAssending=!isAssending"
                                                    aria-hidden="true" style="cursor: pointer;"></i></th>
                                            <th scope="col" class="action_td_btn3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="faqData?.length">
                                        <tr
                                            *ngFor="let items of faqData | paginate:{itemsPerPage:itemsPerPage, currentPage:pageNumber, totalItems:totalRecords}; let i=index">
                                            <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                            <td class="faq-ques">{{items?.title}}</td>
                                            <td class="faq-ques">{{items?.language || '--'}}</td>
                                            <td class="faq-ques new-qsn">
                                                <a [href]="items?.videoUrl" target="_blank" rel="noopener noreferrer"
                                                    style="color: #ff3b64;">
                                                    {{items?.videoUrl || 'NA'}}
                                                </a>
                                            </td>
                                            <td class="faq-ques">{{items?.createdAt | date:'medium'}}</td>
                                            <td class="action_td_btn3">
                                                <a (click)="viewFaq(items._id)"
                                                    *ngIf="service.isShow(permissionArr,'FIERES_TUTORIAL','READ')"><i
                                                        class="fa fa-eye" aria-hidden="true"></i></a>
                                                <a (click)="editFaq(items._id)"
                                                    *ngIf="service.isShow(permissionArr,'FIERES_TUTORIAL','UPDATE')"><i
                                                        class="fa fa-edit" aria-hidden="true"></i></a>
                                                <a (click)="delete(items._id)"
                                                    *ngIf="service.isShow(permissionArr,'FIERES_TUTORIAL','DELETE')"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!faqData?.length">
                                        <tr>
                                            <td colspan="10" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <h5>No record found</h5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="totalRecords > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Tutorial </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete
                                    this tutorial?</p>

                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->