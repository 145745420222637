<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">UPDATE WALLET MANAGEMENT</h1>
    </div>
  
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                   
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    [max]="today"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">

                                                <input type="datetime" class="form-control w-75"
                                                    placeholder="Enter Email" formControlName="searchText">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="userStatus" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="PENDING">PENDING</option>
                                                    <option value="COMPLETED">COMPLETED</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left" >
                                                   <input type="text" class="form-control form1" formControlName="ramdomId" placeholder="Random Id">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                   
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <button type="button" class="btn  btn-theme cus-mr"
                                                (click)="exportAsXLSX()">EXPORT AS EXCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                (click)="pageNumber = 1;coinList()">Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <button type="button" class="btn  btn-theme"
                                                (click)="reset()">Clear
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                  
                                    <div class="row col-md-12 justify-content-end">
                                   
                                        <div class="d-flex ">
                                            <!-- <div class=" " style="margin-top:26px;">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="text-left" style="margin-left: 1%">
                                                            <button type="button" class="btn  btn-theme cus-mr"
                                                                (click)="pageNumber = 1;newValue('REWARDED')">REWARDED</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                           
                                        </div>
                            


                                    </div>
                                   
                                </div>

                            </form>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No.
                                        </th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Email Id</th>
                                        <th scope="col">Plan Name</th>
                                        <th scope="col">Contract Address</th>
                                        <th scope="col">New Wallet Address </th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Status</th>
                
                                        <th scope="col">Action</th>
                
                
                                        <!-- <th scope="col">Email</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of coinData | paginate: { 
                                                            itemsPerPage: itemsPerPage,
                                                            currentPage: pageNumber,
                                                            totalItems: totalRecords };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{item?.userId?.firstName || '--'}}</td>
                                        <td>{{item?.userId?.lastName || '--'}}</td>
                                        <td>{{item?.userId?.email || '--'}}</td>
                                        <td>{{item?.planId?.planName || '--'}}</td>
                                        <td>{{item?.planId?.contractAddress || '--'}}</td>
                                        <!-- <td>{{item?.icoName || '--'}}</td> -->
                                        <!-- <td>{{(item?.tokenQuantity) || '--'}} </td> -->
                                        <!-- <td>{{item?.baseCurrency || '--'}}</td> -->
                                        <td>{{item?.newWalletAddress || '--'}}</td>
                                        <!-- <td>{{item?.tokenReleased || '--'}} </td> -->
                                        <td>{{(item?.createdAt | date:'medium') || '--'}} </td>
                                        <td>{{item?.updateStatus || '--'}}</td>
                                        <td class="action_td_btn3">
                                            <!-- <a><i class="fa fa-eye" [routerLink]="['/token-transaction-view']"
                                                    [queryParams]="{userId : userId}" aria-hidden="true"></i></a> -->
                                          <ng-container *ngIf="userDetail?.role == 'ADMIN'">
                                            <a *ngIf="item?.updateStatus == 'PENDING' "><i class="fa fa-check" style="color: green;"
                                                (click)="twoFAStatusFun(item?._id, item)" aria-hidden="true"></i></a>
                                          </ng-container>
                                          <ng-container *ngIf="userDetail?.role != 'ADMIN'">
                                            <a *ngIf="item?.updateStatus == 'PENDING' "><i class="fa fa-check" style="color: green;"
                                                (click)="functionNew()" aria-hidden="true"></i></a>
                                          </ng-container>
                                        </td>
                                    </tr>
                                    <tr *ngIf="coinData?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin: auto; text-align: center;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer> -->


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Remove User </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Block User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- accept token modal -->

<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Approve</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to approve kyc with data entered?</p> -->
                                <p style="font-size: 25px;">Are you sure you want to accept this token?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="approveKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- <two-fa-modal *ngIf="mfa" [parameter]="params" (close)="mfa = false" (onVerify)="verfyAuth($event)"></two-fa-modal> -->
<two-fa-modal *ngIf="mfa" [userDetail]="userDetail" [parameter]="params" (close)="mfa = false" (onVerify)="verfyAuth($event)"></two-fa-modal>
