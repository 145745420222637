<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View Tutorial</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <form [formGroup]="viewFaqForm">
                            <div class="form-group row ">
                                <label class="col-md-4">Title :</label>
                                <div class="col-md-8 col-sm-12">
                                    <!-- <input type="text" class="form-control " formControlName="title" readonly /> -->
                                    <p>{{editData?.title}}</p>
                                </div>

                            </div>
                            <div class="form-group row ">
                                <label class="col-md-4">Language :</label>
                                <div class="col-md-8 col-sm-12">
                                    <!-- <input type="text" class="form-control " formControlName="title" readonly /> -->
                                    <p>{{editData?.language || '--'}}</p>
                                </div>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Image:</label>
                                <div class="col-md-8 col-sm-12">
                                    <img [src]="editData?.imageUrl" alt="">
                                </div>
                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Video Url:</label>
                                <div class="col-md-8 col-sm-12">
                                    <a [href]="editData?.videoUrl" target="_blank" rel="noopener noreferrer" style="color: #ff3b64;">{{editData?.videoUrl}}</a>
                                    <!-- <iframe  [src]="safeSrc" width="560" height="315"
                                        frameborder="0" webkitallowfullscreen mozallowfullscreen
                                        allowfullscreen></iframe> -->
                                </div>
                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Button Url:</label>
                                <div class="col-md-8 col-sm-12">
                                    <a [href]="editData?.link" target="_blank" rel="noopener noreferrer" style="color: #ff3b64;">{{editData?.link}}</a>
                                    <!-- <iframe  [src]="safeSrc" width="560" height="315"
                                        frameborder="0" webkitallowfullscreen mozallowfullscreen
                                        allowfullscreen></iframe> -->
                                </div>
                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Description:</label>
                                <div class="col-md-12 col-sm-12">
                                    <p [innerHtml]="editData?.description">{{editData?.description}}</p>

                                </div>
                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Date & Time:</label>
                                <div class="col-md-8 col-sm-12">
                                    <p>{{editData?.createdAt | date:'medium'}}</p>

                                </div>
                            </div>
                            <div class="text-center mt40">

                                <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey"
                                    [routerLink]="['/fieres-tutorial-list']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>