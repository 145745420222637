<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">VIEW PAYMENT</h1>
        </div>

        <!-- tab General -->

        <div class="tab-pane active show">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">First Name</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.userId?.firstName}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Last Name</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.userId?.lastName}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Email</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.userId?.email}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Received Amount</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.orderStatus == 'CONFIRMED' ? payment_details.priceAmount : 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Assets Amount</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.priceAmount}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">GBP Amount</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.gbpAmount}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">IBI ID</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label>{{payment_details?.userId?.ibiId}}</label>
                            </div>

                        </div>
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Card Number</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label class="user-eml">{{payment_details?.nubmer}}</label>
                            </div>
                        </div> -->
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">IBI Name</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.userId?.ibiName}}</label>
                            </div>
                        </div>
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Currency</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.currency}}</label>
                            </div>
                        </div> -->
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">ID</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?._id}}</label>
                            </div>
                        </div>
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4">TxnId</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.txnId}}</label>
                            </div>
                        </div> -->
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Card Id</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label  style="word-break: break-word;">{{payment_details?.cardId}}</label>
                            </div>
                        </div> -->

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Pay Addresss</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.pay_address}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Pay Amount</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.priceAmount}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Payment ID</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.orderId}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Payment Status</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.orderStatus}}</label>
                            </div>
                        </div>
                       
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Allocated Amount</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{payment_details?.buyAmount}}</label>
                            </div>
                        </div>


                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Date & Time</label>
                            <div class="col-md-1 sm-d-none white">:</div>
                            <div class="col-md-7">
                                <label class="user-eml">{{payment_details?.createdAt | date :'medium'}}</label>
                            </div>
                        </div>








                    </div>
                    <div class="text-left mt40 text-center">
                        <a (click)="back()" class="btn btn-large  max-WT-200 font-100 btn-green backBtn ">Back</a>
                        <!-- <a (click)="reply()" class="btn btn-large  max-WT-200 font-100 btn-green ml-2">Reply</a> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- tab genral end -->


        <!-- tab login session end -->

    </div>
</main>
<footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>