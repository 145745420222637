<div class="row nlp">
    <div class="col-5">
        <label for="">Available Fiero Balance</label>
    </div>
    <div class="col-1">:</div>
    <div class="col-5 d-flex">
       <p class="amt">{{walletBalance?.unlockedTokenAmount || 0}}</p>
       <p class="slt">(${{toFixed(walletBalance?.unlockedTokenAmount * saleData?.priceWithoutMark,2)}})</p>
        
    </div>

    <div class="col-5">
        <label for="">Locked Fiero Balance</label>
    </div>
    <div class="col-1">:</div>
    <div class="col-5 d-flex">
       <p class="amt">{{walletBalance?.amount || 0}}</p>
       <p class="slt">(${{toFixed(walletBalance?.amount * saleData?.priceWithoutMark,2)}})</p>
        
    </div>
</div>
<div class="tab-pane1">
    <!-- ******************** This is the filter section start here ********************* -->
    <div class="tab-pane active show">
        <div class="table-responsive">
            <table class="table table-bordered" aria-describedby="user list">
                <thead>
                    <tr class="no_wrap_th">
                        <th scope="col">S.No.
                        </th>
                        <th scope="col">Transaction Hash

                        </th>
                        <th scope="col">To Address

                        </th>
                        <!-- <th scope="col">From Address

                        </th> -->

                        <!-- <th scope="col">USDT</th> -->
                        <th scope="col">Amount (FIERO)
                        </th>
                        <th scope="col">TransactionType

                        </th>
                        <th scope="col">Transaction Id

                        </th>
                        <th scope="col">Transaction Fee </th>
                        <th scope="col">Currency</th>
                        <th scope="col">Status</th>
                        <th scope="col">Order Status</th>


                        <!-- <th scope="col">Email</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of withdrawlData;let i = index">
                        <td>{{i + 1}}</td>
                        <td >

                            <div style="display: inline-block; margin-right: 5px; ">{{item?.transactionHash && shortAddress(item?.transactionHash) || '--'}}
                            </div><i class="fa fa-clone" *ngIf="item.transactionHash" (click)="copyToClipboard(item.transactionHash)" aria-hidden="true"></i>
                        </td>
                        <td style="display: flex;">
                            <div style="display: inline-block; margin-right: 5px; ">{{walletAddress && shortAddress(walletAddress) || '--'}}
                            </div><i class="fa fa-clone" *ngIf="walletAddress" (click)="copyToClipboard(walletAddress)" aria-hidden="true"></i>
                        </td>
                        <!-- <td>{{item?.fromAddress || '--'}}</td> -->
                        <td>{{item?.buyAmount}}</td>
                        <td>{{item?.transactionType}}</td>
                        <td>{{item?.orderId}}</td>
                        <td>{{item?.fees || '0'}}</td>
                        <td>{{item?.priceCurrency}}</td>
                        <td>{{item?.transactionStatus}}</td>
                        <td>{{item?.orderStatus}}</td>
                    </tr>
                    <tr *ngIf="withdrawlData?.length==0">
                        <td colspan="10" vertical-align="middle">
                            <div class="no-record">
                                <div class="no-recordin">
                                    <H5 style="margin: auto;
                                                        text-align: center;">No record found</H5>
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="withdrawlData?.length>20">
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div> -->
        <div class="text-left mt40 text-center">
            <a routerLink="/user-management" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
        </div>
    </div>

    <!-- ******************** This is the filter section end here ********************* -->


    <!-- tab withdraw -->

    <!-- tab withdraw end -->
    <!-- tab deposite -->
    <div class="tab-pane active show" *ngIf="this.currTab == 'Deposite'">
        <div class="table-responsive">
            <table class="table table-bordered" aria-describedby="user list">
                <thead>
                    <tr class="no_wrap_th">
                        <th scope="col">ID</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Transaction Hash</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let data of depositeData | paginate:{itemsPerPage: 20, currentPage: pageNumber,totalItems: depositeData?.length} ; let i=index;">
                        <td>{{20*(this.pageNumber-1)+i+1}}</td>
                        <td>{{data?.coinType == 'AVT' ? 'Toga' : data?.coinType}}</td>
                        <td>{{data?.txnType}}</td>
                        <td style="white-space: nowrap;">{{data?.txnTime | date}}</td>
                        <td style="white-space: nowrap;"> {{data?.txnHash == null ? 'Transaction Bought' : data?.txnHash
                            }}</td>
                        <td>{{data?.amount}}</td>
                        <td>{{data?.status == 'SUCCESS' ? 'SUCCESSFUL' : data?.status}}</td>
                    </tr>
                    <tr *ngIf="depositeData?.length==0">
                        <td colspan="10" vertical-align="middle">
                            <div class="no-record">
                                <div class="no-recordin">
                                    <H5 style="margin: auto;
                                                        text-align: center;">No record found</H5>
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
            *ngIf="depositeData?.length>20">
            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
        </div>
    </div>

    <!-- tab deposite end-->


</div>
<!-- </div>
            </div>
        </div>
    </div>
</main> -->
<!-- 
<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer> -->


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Remove User </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Block User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>