import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { contractHelper } from 'src/assets/js/contractHelper';

declare var $
declare var Web3
declare let window: any;
@Component({
  selector: 'app-vesting-plan-add',
  templateUrl: './vesting-plan-add.component.html',
  styleUrls: ['./vesting-plan-add.component.css']
})
export class VestingPlanAddComponent implements OnInit {
  selectedOption: string = 'option1'; // Variable to store the selected option
  isRadioEnabled = true; // Initial state is enabled
  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  userId: any
  today: any = new Date().toISOString().slice(0, 16)
  loylity: FormGroup
  // Creating an array in JavaScript
  daysList = [
    { name: 3 },
    { name: 30 },
    { name: 60 },
    { name: 90 },
    { name: 120 },
    { name: 180 },
    { name: 365 },
    { name: 730 }
  ];
  dataListRelease = [
    { name: 15 },
    { name: 30 },
    { name: 180 },
    { name: 365 },
    { name: 550 },
    { name: 730 },
    { name: 920 },
    { name: 1095 },
    { name: 1280 },
    { name: 1460 }
  ]
  // daysList = [{name:3},
  //   {name:30},
  //   {name:60},
  //   {name:90},
  //   {name:120},
  //   {name:360},
  //   {name:720}
  // ];
  // dataListRelease = [{name:3},
  //   {name:30},
  //   {name:60},
  //   {name:90},
  //   {name:120},
  //   {name:360},
  //   {name:720}
  // ];
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute, private datePipe: DatePipe) {

    // this.active.queryParams.subscribe((params) => {
    //   this.currTab = params.tab
    //   this.userId = params.userId
    //   if (params.userId) {
    //     this.showUserDetail()
    //   }
    //   console.log('jjf', this.currTab);

    // })
  }
  pattern = /^\d*\.?\d+$/
  ngOnInit(): void {
    this.formValidation();
    this.getFieroPrice();
    this.viewFee();
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'planName': new FormControl('', [Validators.required]),
      'tokenPrice': new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
      'tokenSellLimit': new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
      'terms': new FormControl('', [Validators.required]),
      'planStartdate': new FormControl('', [Validators.required]),
      'planEndDate': new FormControl('', [Validators.required]),
      'withdrawaCommission': new FormControl('', [Validators.required,  Validators.pattern(this.pattern)]),
      'releaseTimePeriod': new FormControl('', [Validators.required]),
      'image': new FormControl(''),
      'minGbpAmount': new FormControl('',[Validators.required,  Validators.pattern(this.pattern)]),
      'salePrice': new FormControl('POOL', [Validators.required])

    })

    // this.addFaqForm.patchValue({
    //   planName: 'test',
    //   tokenPrice: 1000,
    //   tokenSellLimit: 1000,
    //   terms: 3,
    //   planStartdate: "2024-05-14",
    //   planEndDate: "2024-05-30",
    //   withdrawaCommission: "0",
    //   releaseTimePeriod: 15,
    //   // image: "https://twistcryptocards-bucket.s3.ap-southeast-1.amazonaws.com/uploads/17150009212051715000921108_17108404209961710840420748_Freecoupon-2.png",
    //   minGbpAmount: "10"
    // })
  }

  // Function to handle the change event when a radio button is clicked
  onOptionChange(): void {

    //  if(this.addFaqForm.value.salePrice === 'TRANC'){
    this.getFieroPrice()
    //  }

  }
  openModalFun() {
    this.isRadioEnabled = !this.isRadioEnabled;
    $('#markUpModal').modal('show')
  }

  showData: any

  fieroPrice: any
  getFieroPrice() {
    let url = `vesting/getSaleList`
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        
        let findEnable = res.data.docs.find((ele) => {
          return ele.icoStatus == "ENABLE"
        })
        console.log(findEnable);

        this.fieroPrice = Number(findEnable['price'])
        if (findEnable) {

          this.fieroPrices = Number(findEnable['priceWithoutMark'])
          this.finalPrice = Number(findEnable['price'])
        }
        if (this.addFaqForm.value.salePrice === 'TRANC') {
          if (this.markTab == 'UP' || this.markTab == 'DOWN') {
            this.addFaqForm.patchValue({
              tokenPrice: this.finalPrice
            })
          } else {
            this.addFaqForm.patchValue({
              tokenPrice: this.fieroPrices
            })
          }
        }
      } else {

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  isLoading = false
  async contractDeploy() {
    try {
      this.isLoading = true
      if (window.ethereum) {
        try {
          const chainId = 97; // BSC Testnet Chain ID
          const web3 = new Web3(window.ethereum);
          if (parseInt(window.ethereum.networkVersion, 10) !== chainId) {
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
              });
            } catch (err) {
              // Handle the case where the chain has not been added to MetaMask
              if (err.code === 4902) {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainName: 'BSC Testnet',
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: {
                        name: 'Testnet BNB',
                        symbol: 'tBNB',
                        decimals: 18
                      },
                      rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                      blockExplorerUrls: ['https://testnet.bscscan.com']
                    }
                  ]
                });
              } else {
                this.service.toasterErr(err.message);
                console.error(err);
                this.isLoading = false;
              }
            }
          }
        } catch (error) {
          console.error(error);
          this.service.toasterErr(error.message);
          this.isLoading = false;
          return;
        }
        try {
          
          const constant = new contractHelper()
          const contractAbi = constant.nodeContractDeployAbi; // Your contract's ABI (Array of objects)
          const contractBytecode = constant.nodeContractMetaData; // Your contract's bytecode
          let paramObj = {
            adminComm: this.addFaqForm.value.withdrawaCommission,
            vestingEndDay: this.addFaqForm.value.terms,
            claimEndDay: this.addFaqForm.value.releaseTimePeriod,
          }

          let isConnected = await window.ethereum.request({ method: 'eth_requestAccounts' });

          const web3 = new Web3(window.ethereum);
          const contract = new web3.eth.Contract(contractAbi);

          // Estimate gas for deployment
          // const gasEstimate = await contract.deploy({
          //   data: contractBytecode,
          //   arguments: [
          //     Number(paramObj.adminComm),
          //     // Number(paramObj.vestingEndDay),
          //     // Number(paramObj.claimEndDay)
          //   ]
          // }).estimateGas({ from: isConnected[0] });

          // Prepare the raw transaction object
          const rawTransaction = {
            from: isConnected[0],
            gas: 2000000,
            gasPrice: 30000000000,

          };

          // Deploy the contract
          const deployTransaction = await contract.deploy({
            data: contractBytecode,
            arguments: [
              Number(paramObj.adminComm),
              Number(paramObj.vestingEndDay),
              Number(paramObj.claimEndDay)
            ]
          }).send(rawTransaction);

          this.addVestingPlan(deployTransaction._address)
        } catch (error) {
          this.isLoading = false
          console.log(error)
          this.service.toasterErr(error.message)
        }
      }
      else {
        this.isLoading = false
        console.error('MetaMask not found');
        this.service.toasterErr('MetaMask not found')


        this.isLoading = false
      }

    } catch (error) {
      console.log("contract deploy error.", error)
      this.service.toasterErr(error.message)

      console.log(error);
      this.isLoading = false

    }
  }
  addVestingPlan(contractAddress) {
    let url = `vesting/addVestingPlan`
    let enable
    let markStatus
    if (this.addFaqForm.value.salePrice === 'TRANC') {
      enable = 'ENABLE'
      markStatus = this.markTab
    } else {
      enable = 'DISABLE'
      this.markDownPercentage = 0;
      this.markUpPercentage = 0;
      markStatus = 'NOMARK'
    }


    let formData = new FormData()
    formData.append("comission", this.addFaqForm.value.withdrawaCommission)
    formData.append("endDate", this.addFaqForm.value.planEndDate)
    formData.append("planName", this.addFaqForm.value.planName)
    formData.append("releaseTerm", this.addFaqForm.value.releaseTimePeriod)
    formData.append("sellLimit", this.addFaqForm.value.tokenSellLimit)
    formData.append("startDate", this.addFaqForm.value.planStartdate)
    formData.append("terms", this.addFaqForm.value.terms)
    formData.append("contractAddress", contractAddress)
    formData.append("minGbpAmount", this.addFaqForm.value.minGbpAmount)
    formData.append("tokenPrice", String(this.addFaqForm.value.tokenPrice))
    formData.append('image', this.myImageFIle)
    formData.append("vestingPlanType", this.addFaqForm.value.salePrice)
    formData.append("markDownPercentage", this.markTab == "NOMARK" ? 0 : (this.markTab == "DOWN" ? this.markDownPercentage : 0))
    formData.append("markStatus", enable)
    formData.append("markType", markStatus)
    formData.append("markUpPercentage", this.markTab == "NOMARK" ? 0 : (this.markTab == "UP" ? this.markUpPercentage : 0))
    this.service.showSpinner()
    this.service.post(url, formData).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/vesting-plan-list'])
        this.service.hideSpinner()
        this.isLoading = false
      } else {
        this.isLoading = false
        this.service.showSpinner()
      }
    }, err => {
      this.isLoading = false
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  myImageFIle: any
  myImageNew: any
  myImage: any
  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];

    if (file) {
      this.previewImage(file);
    }
  }
  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.myImageNew = reader.result;
    };
    $('#imgCheck').modal('show')
    reader.readAsDataURL(file);
    this.myImageFIle = file
  }
  imageUplaod() {
    // this.uploadImageFunc(this.myImageFIle);
    $('#imgCheck').modal('hide')
  }
  /** to call form data */
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.myImage = res['data'];
        $('#imgCheck').modal('hide')

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }

  // markup and markdown value

  markDownPercentage: any
  markUpPercentage: any
  priceType = ""
  viewFee() {
    /* this.service.showSpinner(); */
    var url = "wallet/coinDetail"
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.priceType = res.data.markStatus == 'ENABLE' ? 'MARK' : 'TRANCHES'
        this.markTab = res.data.markType
        this.percentageValue = this.markTab == 'UP' ? res.data.markUpPercentage : this.markTab == 'DOWN' ? res.data.markDownPercentage : 0
        this.markDownPercentage = res.data.markDownPercentage
        this.markUpPercentage = res.data.markUpPercentage
      }
    }, (err) => {
      if (err.status == 401) {
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized access')
      }
      else {
        this.service.toasterErr('something went wrong')
      }
    })
  }
  coinList: any = [];
  cointAmount: any = []
  price: any = []
  startDate: any = []
  endDate: any = []
  supply: any = []
  usd: any = []
  fieroPrices: number
  finalPrice: any

  markTab = ''
  setMarkTab(e) {
    this.markTab = e
    this.setFinalPrice(Number(this.markTab == 'UP' ? this.markUpPercentage : this.markDownPercentage))
  }
  percentageValue: any
  percentage: any
  setMarkupPercentage() {
    this.percentage = 0
    if (this.markTab == 'UP') {
      this.percentage = this.markUpPercentage
    }
    else if (this.markTab == 'DOWN') {
      this.percentage = this.markDownPercentage
    }
    console.log(this.percentage);

    $('#markUpModal').modal('hide')
    // this.getFieroPrice()     
    if (this.addFaqForm.value.salePrice === 'TRANC') {
      if (this.markTab == 'UP' || this.markTab == 'DOWN') {
        this.addFaqForm.patchValue({
          tokenPrice: this.finalPrice
        })
      } else {
        this.addFaqForm.patchValue({
          tokenPrice: this.fieroPrices.toFixed(2)
        })
      }
    }
  }
  setFinalPrice(e: number) {

    e = e > 100 ? 100 : e < 0 ? 0 : e
    if (this.markTab == 'UP') {
      this.finalPrice = Number(this.fieroPrices + (this.fieroPrices * e / 100)).toFixed(2)
    }
    else if (this.markTab == 'DOWN') {
      this.finalPrice = Number(this.fieroPrices - (this.fieroPrices * e / 100)).toFixed(2)
    }
    else {
      this.finalPrice = this.fieroPrices
    }
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
}
