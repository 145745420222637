<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">VIEW BROADCAST</h1>

        </div>
        <!-- Page Title End -->

        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style" style="text-align: justify;">
                        <!-- image upload start-->
                        <!-- <div class="user-profile">
                            <div class="image-box">
                                <img [src]="editImage || 'assets/img/testimonial-img1.jpg' " class="profile-pic"
                                    alt="profile image">

                            </div>
                        </div> -->
                        <!-- image upload end -->


                        <div class="form-group row align-items-justify">
                            <label class="col-md-4">Title :</label>

                            <div class="col-md-8">
                                <input ngModel="{{editData?.title}}" class="form-control" style="font-size: 14px;"
                                    readonly>
                            </div>
                        </div>




                        <div class="form-group row align-items-justify">
                            <label class="col-md-4">Description :</label>

                            <div class="col-md-8" style="text-align: center;">
                                <!--  <label>{{editData?.description}}</label> -->
                                <textarea class="form-control" name="" id="" cols="10" rows="4" readonly
                                    style="background: transparent;font-size: 14px;">{{editData?.description}}</textarea>
                            </div>
                        </div>

                        <div class="text-center mt40">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey"
                                [routerLink]="['/broadcast-management']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>