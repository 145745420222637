<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black"> EDIT BROADCAST</h1>

        </div>
        <!-- Page Title End -->

        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <!-- image upload start-->
                        <!-- <div class="user-profile">
                                                <div class="image-box">
                                                    <img [src]="editImage || 'assets/img/testimonial-img1.jpg' " class="profile-pic" alt="profile image">
                                                    <label class="upload-label">
                                                          <input type="file" class="hidden" accept=".png, .jpg, .jpeg"
                                                              (change)="uploadImg($event)" />
                                                          <i class="fas fa-camera" aria-hidden="true"></i>
                                                      </label>
                                                </div>
                                            </div> -->
                        <!-- image upload end -->
                        <form [formGroup]="announcementForm" style="text-align:justify;">
                            <div class="form-group row ">
                                <label class="col-md-4">Announcement:</label>
                                <div class="col-md-8">
                                    <input type="text" class="form-control " formControlName="page"
                                        (keypress)="service.preventSpace($event)" style="font-size: 14px;" />

                                    <span class="error">
                                        <p *ngIf="announcementForm.get('page').hasError('required') && (announcementForm.get('page').dirty || announcementForm.get('page').touched )"
                                            class="error" padding>*Field is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="announcementForm.get('page').hasError('pattern') && (announcementForm.get('page').dirty || announcementForm.get('page').touched )"
                                            class="error" padding>*Please enter valid page name</p>
                                    </span>
                                </div>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Description:</label>
                                <div class="col-md-8">
                                    <textarea class="ex1" class="form-control" rows="4" cols="50"
                                        formControlName="description" (keypress)="service.preventSpace($event)"
                                        style="font-size: 14px;"></textarea>

                                </div>

                            </div>
                            <div class="text-center" style="margin-top: 4%; margin-left: 12%;">
                                <button href="javascript:void(0)"
                                    class="btn btn-large  max-WT-200 font-100 btn-green mr-5 " style="width: 10%;"
                                    [disabled]="!announcementForm.valid" (click)="updateAnnouncement()">Update</button>
                                <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                    style="width: 10%;" [routerLink]="['/broadcast-management']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Announcement </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this Announcement?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->