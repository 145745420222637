<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View Faq</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <form [formGroup]="viewFaqForm">
                            <div class="form-group row ">
                                <label class="col-md-4">Question :</label>
                                <div class="col-md-8 col-sm-12">
                                    <input type="text" class="form-control " formControlName="title" readonly />

                                    <span class="error">
                                        <p *ngIf="viewFaqForm.get('title').hasError('required') && (viewFaqForm.get('title').dirty || viewFaqForm.get('title').touched )"
                                            class="error" padding>*Please enter question</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="viewFaqForm.get('title').hasError('pattern') && (viewFaqForm.get('title').dirty || viewFaqForm.get('title').touched )"
                                            class="error" padding>*Please enter valid question</p>
                                    </span>
                                </div>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-4">Answer:</label>
                                <div class="col-md-8 col-sm-12">
                                    <textarea class="ex1" class="form-control" style=" height: 123px;
                              margin-top: -25px;
                              margin-bottom: 0px;" cols="4" rows="5" formControlName="description"
                                        (keypress)="service.preventSpace($event)" readonly></textarea>

                                </div>
                            </div>
                            <div class="text-center mt40">

                                <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey"
                                    [routerLink]="['/faq-management']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>