<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">USER MANAGEMENT</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">

                                    <div class="col-md-2 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-2 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    [max]="today"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <br>


                                    <div class="col-md-2 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="country" class="form-control form1">
                                                    <option value="">Select Country</option>
                                                    <option *ngFor="let code of products" value="{{code.name}}">
                                                        {{code?.name}}</option>


                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="userStatus" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="ACTIVE">Active</option>
                                                    <option value="BLOCK">Block</option>
                                                    <option value="UNVERIFIED">Unverified</option>
                                                    <!-- <option value="DELETED">Delete</option> -->
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <input type="text" class="form-control w-75" style="padding-left: 2%;"
                                                    placeholder="Search by email" formControlName="searchText">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row col-md-12 justify-content-end">
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="text-left" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme cus-mr"
                                                            (click)="pageNumber = 1;getlist()">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme"
                                                            (click)="reset()">Clear


                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;"
                                        *ngIf="service.isShow(permissionArr,'USER_MANAGEMENT','UPDATE')">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 7%">
                                                        <button type="submit" class="btn  btn-theme "
                                                            (click)="exportAsXLSX()" style="margin-right: 10px">EXPORT
                                                            AS EXCEL
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;"
                                            *ngIf="service.isShow(permissionArr,'USER_MANAGEMENT','CREATE')">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 7%">
                                                        <button type="submit" class="btn  btn-theme "
                                                            [routerLink]="['/add-user']">Add User
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 user-lst p-0" style="margin-top: 18px;display: flex;justify-content: end;padding-right: 21px !important;">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="search()">Search</button>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </form>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col"> Full Name</th>
                                        <th scope="col"> Email Id
                                            <i class="fa fa-sort" (click)="sortAgent('email');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i>
                                        </th>
                                        <!-- <th scope="col"> Phone No.
                                        </th> -->
                                        <th scope="col"> Country
                                        </th>
                                        <th scope="col"> Status
                                        </th>

                                        <th scope="col">Date & Time

                                            <i class="fa fa-sort" (click)="sortAgent('createdAt');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i>
                                        </th>
                                        <!--         <th scope="col">User ID</th> -->
                                        <!-- <th scope="col"> Status
                                        </th> -->
                                        <th scope="col"> Action
                                        </th>
                                    </tr>

                                </thead>
                                <tbody *ngIf="listing?.length">
                                    <tr *ngFor="let item of listing | paginate: { 
                                    itemsPerPage: itemsPerPage,
                                    currentPage: pageNumber,
                                    totalItems: totalRecords };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td class="name">{{item?.firstName || '--'}} {{item?.lastName}}
                                        </td>
                                        <td>{{item?.email}}
                                        </td>
                                        <!-- <td>{{item?.phoneNo}} </td> -->
                                        <td>{{item?.country}} </td>
                                        <td>{{item?.status}} </td>
                                        <td>{{item?.createdAt | date : 'medium'}}</td>

                                        <!-- <td>{{item?.status}}
                                        </td> -->


                                        <td class="action_td_btn3">
                                            <a routerLink="/user-details/{{item._id}}" title="view"
                                                *ngIf="service.isShow(permissionArr,'USER_MANAGEMENT','READ')"><i
                                                    class="fa fa-eye" aria-hidden="true"></i></a>
                                            <a routerLink="/add-user" [queryParams]="{'userId': item._id}" title="edit"
                                                *ngIf="service.isShow(permissionArr,'USER_MANAGEMENT','UPDATE')"><i
                                                    class="fa fa-edit" aria-hidden="true"></i></a>
                                            <ng-container  *ngIf="service.isShow(permissionArr,'USER_MANAGEMENT','UPDATE')">
                                                <a (click)="block('ACTIVE',item?._id)"
                                                    *ngIf="item?.userStatus=='BLOCK'" data-toggle="modal"><i
                                                        class="fa fa-ban" aria-hidden="true"></i></a>
                                                <a (click)="block('BLOCK',item?._id)"
                                                    *ngIf="item?.userStatus=='ACTIVE'" data-toggle="modal"><i
                                                        class="fa fa-ban" style="color: green;"
                                                        aria-hidden="true"></i></a>
                                            </ng-container>

                                            <ng-container  *ngIf="service.isShow(permissionArr,'USER_MANAGEMENT','DELETE')">
                                                <a *ngIf="item?.userStatus == 'UNVERIFIED'">
                                                    <em class="fa fa-times  " style="color: #ee6b7b;"></em></a>
                                            </ng-container>
                                            
                                            <!-- <a (click)="delete(item.userId)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a> -->


                                        </td>
                                    </tr>


                                </tbody>
                                <tbody *ngIf="!listing?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">ICO Space crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="userStatus=='ACTIVE'" style="text-align: center; ">Unblock </h3>
                        <h3 *ngIf="userStatus=='BLOCK'" style="text-align: center; color: #fff;">Block </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to
                                    unblock?</p>
                                <p *ngIf="userStatus=='BLOCK'" style="font-size: 25px;  color: #fff;">Are you sure you
                                    want to block?
                                </p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>